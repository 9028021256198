<template>
  <div class="uk-grid-medium" uk-grid>
    <div class="uk-width-5-5">
      <div class="d-flex justify-content-between mb-3">
        <h3>{{ this.$t('general.all_contents') }} ({{ items.total }})</h3>

        <div>
          <router-link class="btn btn-default" :to="{ name: 'company.content.add' }">
            <i class="uil-presentation-plus"> </i> {{ this.$t('general.add_content') }}
          </router-link>
        </div>
      </div>

      <template v-if="errorMessages">
        <div v-for="(error, index) in errorMessages" :key="index" class="uk-alert-danger" uk-alert>
          <a class="uk-alert-close" uk-close></a>
          <p style="color: red">
            <i class="uil-exclamation-triangle"></i> {{ error }}
          </p>
        </div>
      </template>
      <div v-if="successMessage" class="uk-alert-success" uk-alert>
        <a class="uk-alert-close" uk-close></a>
        <p style="color: green">
          <i class="uil-exclamation-triangle"></i> {{ successMessage }}
        </p>
      </div>
      <div class="section-header mb-3">
        <div class="section-header-left">
          <input class="mt-3 mb-3" type="text" :placeholder="$t('general.search_in_contents')" v-model="search" />
        </div>
        <div class="section-header-right">
          <div class="btn-group bootstrap-select ml-3">
            <multiple-select-input :model.sync="filter.orderBy" :multiple="false" :required="false" :searchable="false"
              :showLabels="false" :values="filterTypes" :allowEmpty="false"
              @select="filter.orderBy = $event; sortOrder()" />
          </div>
        </div>
      </div>

      <div class="pb-4 uk-grid justify-content-between" uk-grid="">
        <div class="uk-width-3-4@m uk-first-column">
          <default-loading v-if="content_loading"></default-loading>
          <template v-else>
            <div class="uk-child-width-1-3@m uk-grid uk-first-column"
              uk-scrollspy="target: > div; cls: uk-animation-slide-left-small; delay: 200" uk-grid="">
              <div v-for="(  item, index  ) in   formatteditems  " :key="index">
                <div class="card animate-this uk-inline-clip">
                  <img :src="item.photo_link" alt="" style="height:180px; width:100%" />
                  <div class="card-body text-center pb-3">
                    <h6 class="mb-0" style="overflow:hidden;text-overflow:ellipsis;white-space:nowrap">{{ item.title }}
                    </h6>
                    <span class="d-block mt-1">{{ item.name }}</span>
                    <span class="d-block mt-1">{{ item.category_name }}</span>
                    <span class="d-block mt-1">{{ item.created_at }}</span>
                  </div>
                  <div class="card-body text-center p-3 d-flex flex-column">
                    <!-- <router-link
                    href="#"
                    type="button"
                    class="btn btn-secondary btn-sm mb-2"
                    :to="{
                      name: 'company.contents.preview',
                      params: { id: item.id },
                    }"
                  >
                    <i class="uil-eye"> </i> Önizleme
                  </router-link> -->
                    <router-link href="#" type="button" class="btn btn-secondary btn-sm mb-2" :to="{
                      name: 'company.contents.edit',
                      params: { id: item.id },
                    }
                      ">
                      <i class="uil-cog"> </i>{{ $t('general.edit') }}
                    </router-link>
                    <router-link href="#" type="button" class="btn btn-info btn-sm mb-2" from="content" :to="{
                      name: 'company.assignments.add',
                      params: { data: { id: item.id, title: item.title, name: item.name } }
                    }
                      ">
                      <i class="uil-location-arrow"> </i>{{ $t('menu.company_dashboard.create_assignment') }}
                    </router-link>
                    <a href="#" :class="item.assignments ? 'disabled' : ''" @click.prevent=" removeItem(item.id)"
                      class="btn btn-secondary btn-sm mb-2">
                      <i class="uil-minus-circle"></i> Sil
                    </a>
                  </div>
                </div>
              </div>



            </div>

            <div class="row mt-3">
              <div class="col-12">
                <pagination v-if="pagination && pagination.hasOwnProperty('links')" :pagination="pagination"
                  @changePage=" setPage($event)"></pagination>
              </div>
            </div>
          </template>
        </div>
        <div class="uk-width-expand">
          <div class="sidebar-filter card p-2 uk-sticky" uk-sticky="offset:80 ; media : @s: bottom: true">
            <div class="sidebar-filter-contents">
              <h4 style="text-transform: capitalize">
                <i class="il-filter"></i> {{ this.$t('general.filter') }}
              </h4>
              <label>
                <input class="uk-radio" type="radio" name="filters" @change="setType" :data-id="'all'" data-type="all"
                  checked />
                <span style="margin-left:6px">
                  {{ $t("general.all") }}
                </span>
              </label>

              <ul class="sidebar-filter-list uk-accordion" uk-accordion="multiple: true">
                <li class="uk-open">
                  <a class="uk-accordion-title" href="#"> Türüne Göre </a>
                  <div class="uk-accordion-content" aria-hidden="false" style="height:32vh;overflow-y:scroll;">
                    <div class="uk-form-controls">
                      <label v-for="  item   in   formattedfilters.file_type  " v-if="item.contents_count > 0">
                        <input class="uk-radio" type="radio" name="filters" :data-id="item.id" data-type="file_type_id"
                          @change="setType" />
                        <span class="test">
                          {{ item.name
                          }}<span> ({{ item.contents_count }}) </span>
                        </span>
                      </label>
                      <label v-for="  item   in   formattedfilters.content_type  " v-if="item.contents_count > 0">
                        <input class="uk-radio" type="radio" name="filters" :data-id="item.id" data-type="content_type_id"
                          @change="setType" />
                        <span class="test">
                          {{ item.name
                          }}<span> ({{ item.contents_count }}) </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </li>

                <li class="uk-open">
                  <a class="uk-accordion-title" href="#">
                    Kategori Etiketine Göre
                  </a>
                  <div class="uk-accordion-content" aria-hidden="false">
                    <div class="form-group">
                      <label for="search-tag" style="font-size: 0.75rem"><i class="uil-search"></i> Etiket Arama</label>
                      <input type="text" class="form-control mb-0" id="search-tag" v-model="searchTag"
                        placeholder="Bir etiket yazın" autocomplete="off" />
                    </div>
                    <div class="uk-form-controls" style="overflow-y: scroll; height: 10rem">
                      <label v-for="  item   in   filteredTags  ">
                        <input class="uk-radio" type="radio" name="filters" :data-id="item.name" data-type="hashtag"
                          @change="setType" />
                        <span class="test"> {{ item.name }} </span>
                      </label>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="uk-sticky-placeholder" hidden="" style="height: 721px; margin: 0px 0px 20px"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import store from "@/core/services";
import moment from "moment";
import DefaultLoading from "@/view/components/loading/DefaultLoading";
import Pagination from "@/assets/components/Pagination";

import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";

import module, {
  BASE_URL,
  MODULE_NAME,
  FILTERS_URL,
  FILTERS,
  GET_FILTERS,
  GET_ITEMS,
  DELETE_ITEM,
  ERROR,
  ITEMS,
  LOADING
} from "@/core/services/store/content.module";
export default {
  name: "Content",
  components: { DefaultLoading, MultipleSelectInput, Pagination },
  data() {
    return {
      filterTypes: [
        {
          id: 'title',
          name: this.$t('filter.Filter_by_name')
        },
        {
          id: 'category',
          name: this.$t('filter.Filter_by_category')
        },
        {
          id: 'created_at',
          name: this.$t('filter.Filter_by_date')
        }
      ],
      page: 1,
      per_page: 2,
      timer: undefined,
      successMessage: null,
      errorMessages: [],
      sort_by: "id",
      sort_desc: "desc",
      search: '',
      searchTag: '',
      filter: {
        orderBy: { "id": "created_at", "name": "Tarihe Göre" },
        sort: null,
        hashtag: null,
        file_type_id: null,
        content_type_id: null,
      },
      pagination: null,
    };
  },
  beforeCreate() {
    function registerStoreModule(moduleName, storeModule) {
      if (!(store && store.state && store.state[moduleName])) {
        store.registerModule(moduleName, storeModule);
      }
    }
    registerStoreModule(MODULE_NAME, module);
  },
  computed: {
    filters: {
      get() {
        return store.getters[MODULE_NAME + "/" + FILTERS];
      },
      set(value) { },
    },
    content_loading: {
      get() {
        return store.getters[MODULE_NAME + "/" + LOADING];
      },
      set(value) { },
    },
    filteredTags() {
      const tags = this.formattedfilters.hashtags
      const search = this.searchTag
      if (search == '' || tags == null) return tags
      return tags.filter((tag) => {
        return tag.name.toLowerCase().includes(search.toLowerCase());
      });
    },
    items: {
      get() {
        return store.getters[MODULE_NAME + "/" + ITEMS];
      },
      set(value) { },
    },
    formattedfilters: {
      get() {
        let filters = this.filters;
        if (filters === null) return [];
        return filters;
      },
    },
    formatteditems: {
      get() {
        let results = [];
        let items = this.items.data;

        this.pagination = this.items;

        if (items === null || undefined == items) return [];
        for (let i = 0; i < items.length; i++) {
          let currentItem = items[i];
          if (currentItem) {
            results.push({
              title: currentItem.title,
              name: currentItem.name,
              category_name: currentItem.category_name,
              id: currentItem.id,
              key_name: currentItem.key_name,
              photo_link: currentItem.cover_photo_link,
              created_at: moment(currentItem.created_at).format("DD/MM/YYYY HH:mm:ss"),
              assignments: currentItem.assignments_count > 0 ? true : false
            });
          }
        }
        return results;
      },
    },
    error: {
      get() {
        return store.getters[MODULE_NAME + "/" + ERROR];
      },
      set(value) { },
    },
  },
  methods: {
    filterResult() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
        filters: {
          search: this.search,
          page: this.page,
          per_page: this.per_page,
          orderBy: this.filter.orderBy.id,
          sort: this.filter.sort,
          hashtag: this.filter.hashtag,
          file_type_id: this.filter.file_type_id,
          content_type_id: this.filter.content_type_id,
        },
      });
    },
    removeItem(id) {
      this.$store
        .dispatch(MODULE_NAME + "/" + DELETE_ITEM, {
          url: BASE_URL + "/" + id,
        })
        .then(() => {
          this.scrollToTop()
          if (!this.error) {
            this.successMessage = this.$t("general.successfully_deleted");
            this.filterResult();
          } else this.errorMessages.push(this.error)
        });
    },
    sortOrder() {
      this.page = 1;
      if (this.filter.orderBy.id == "title" || this.filter.orderBy.id == "category") {
        this.filter.sort = "asc"
      }
      this.filterResult();
    },
    setType(event) {
      let target = event.target;
      let id = target.getAttribute("data-id");
      let type = target.getAttribute("data-type");
      this.filter.file_type_id = this.filter.content_type_id = this.filter.hashtag = null
      if (type == 'hashtag')
        this.filter.hashtag = id
      else if (type == 'file_type_id')
        this.filter.file_type_id = id
      else if (type == 'content_type_id')
        this.filter.content_type_id = id
      else if (type === 'all') {
        this.getItems();
      }
      type !== 'all' ? this.debouncedFilterType() : null
    },
    setPage(page) {
      this.page = page;
      this.filterResult();
    },
    getContentFilters() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_FILTERS, {
        url: FILTERS_URL,
      });
    },
    getItems() {
      this.$store.dispatch(MODULE_NAME + "/" + GET_ITEMS, {
        url: BASE_URL,
      });
    },
    resetMessages() {
      this.successMessage = null;
      this.errorMessages = [];
    },
    scrollToTop() {
      window.scroll({
        top: 0,
        behavior: "smooth",
      });
    },
  },
  mounted() {
    this.getContentFilters();
    this.getItems();
    $(".selectpicker").selectpicker();
  },
  watch: {
    search: function (val) {
      this.debouncedFilterSearch()
    },
    successMessage: function (val) {
      this.debouncedResetValues();
    },
    errorMessages: function (val) {
      this.debouncedResetValues();
    },
  },
  created: function () {
    this.debouncedFilterSearch = _.debounce(this.filterResult, 500)
    this.debouncedFilterType = _.debounce(this.filterResult, 300)
    this.debouncedResetValues = _.debounce(this.resetMessages, 2000);
  }
};
</script>
<style scoped>
@import "~vue-multiselect/dist/vue-multiselect.min.css";

input[type="button"],
input[type="submit"],
span.btn,
.btn {
  display: inline-block;
}
</style>

<style>
.multiselect__tag {
  color: white;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  background-color: #3e416d;
}

.multiselect__tag-icon {
  background-color: transparent;
  line-height: 2.2;
}

.multiselect__tag-icon:after {
  color: #fff;
}

.multiselect__tag-icon:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.multiselect__input {
  border: unset !important;
}

.multiselect__tags .multiselect__input {
  border: unset !important;
}
</style>
